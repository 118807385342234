<template>
    <div class="col-6">
        <v-sheet class="dense-inputs">
            <v-row no-gutters>
                <v-col cols="12" lg="12" md="12" xs="12" class="d-flex flex-row float-right">
                    <v-text-field
                        :label="$t('message.filterResults')"
                        :value="searchTerm"
                        autocomplete="off"
                        class="mt-2 mb-0 force-text-left"
                        prepend-inner-icon="mdi-filter-outline"
                        @change="searchTerm = $event"
                    ></v-text-field>
                    <v-btn class="ml-3" :loading="loading.filterResults" @click="filterResults">{{ $t('message.filter') }}</v-btn>
                    <v-btn class="ml-3" @click="searchTerm = null"><v-icon>refresh</v-icon></v-btn>
                    <ExportTable
                        :has-add-function="true"
                        :cols-to-delete="colsToDelete"
                        class="mt-0 mr-0"
                        file-name="grades"
                        table-id="gradesTable"
                        style="padding-top: 2px !important;"
                        @add-action="openCreateGradeDialog()"
                    ></ExportTable>
                    <HelpPopUpV2 help-page-component="GradesListing" :no-padding="true"></HelpPopUpV2>
                </v-col>
            </v-row>
        </v-sheet>
        <v-overlay
            :value="loading.grades"
            absolute
            opacity="0.15"
        >
            <v-row>
                <v-col class="text-center">
                    <v-progress-circular
                        color="primary"
                        indeterminate
                        size="40"
                        width="6"
                    ></v-progress-circular>
                </v-col>
            </v-row>
        </v-overlay>
        <v-data-table
            :footer-props="{
                itemsPerPageOptions: [20,50,-1],
                showCurrentPage: true,
                showFirstLastPage: true
            }"
            :headers="headers"
            :height="tableHeight"
            :items="allGrades"
            :items-per-page="-1"
            :search="searchTerm"
            :options.sync="tableOptions"
            calculate-widths
            class="mt-0 appic-table-light specification-table"
            dense
            fixed-header
            id="gradesTable"
            item-key="Grade.id"
        >
            <template v-slot:item.index="{ item }">
                <div class="text-center">{{ allGrades.indexOf(item) + 1}}</div>
            </template>
            <template v-slot:item.Grade.title="{ item }">
                <span class="font-weight-bold">{{ item.Grade.title }}</span>
            </template>
            <template v-slot:item.Parent.title="{ item }">
                <span v-if="item.Parent.title != null">{{ item.Parent.title }}</span>
                <span class="red--text" v-if="item.Parent.title == null">{{ $t('message.pleaseProvide') }}</span>
            </template>
            <template v-slot:item.Grade.productgroups="{ item, index }">
                <v-chip class="mr-1 mb-1" v-for="product in item.Grade.productGroupNames" small :key="index">{{ product }}</v-chip>
            </template>
            <template v-slot:item.Grade.id="{ item }">
                <div class="text-center">
                    <v-menu>
                        <template v-slot:activator="{ on: menu }">
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on: tooltip }">
                                    <v-btn icon v-on="{ ...tooltip, ...menu}" class="ml-1">
                                        <v-icon>more_vert</v-icon>
                                    </v-btn>
                                </template>
                                <span>{{ $t('message.moreActions') }}</span>
                            </v-tooltip>
                        </template>
                        <v-list dense>
                            <v-list-item class="font-sm" @click="openEditGradeDialog(item.Grade.id, item.Grade.title)">
                                <v-icon color="black lighten-1" small class="mr-1">edit</v-icon>{{ $t('message.updateGrade') }}
                            </v-list-item>
                            <v-list-item class="font-sm" @click="openConvertDialog(item.Grade.id, item.Grade.title)" v-if="false">
                                <v-icon small class="mr-1" color="blue darken-1">fas fa-file-export</v-icon>{{ $t('message.convertToAnotherGrade') }}
                            </v-list-item>
                            <v-list-item class="font-sm" @click="deleteGrade(item.Grade.id, item.Grade.title)"><v-icon small class="mr-1" color="red lighten-1">cancel</v-icon>{{ $t('message.deleteGrade') }}</v-list-item>
                        </v-list>
                    </v-menu>
                </div>
            </template>
        </v-data-table>
        <AddGrade
            :dialog.sync="editDialog"
            :grade-id="selectedGradeId"
            :grade-name="selectedGradeName"
            :page-key="pageKey"
            :update-mode="updateMode"
            @dialog-closed="editDialogClosed"
            @update-done="gradeUpdated"
            @create-done="gradeCreated"
        ></AddGrade>
    </div>
</template>

<script>
// import {mapFields} from "vuex-map-fields";
import {mapActions, mapGetters} from "vuex";

const AddGrade = () => import("Components/Appic/AddGrade");
const ExportTable = () => import("Components/Appic/ExportTable");
const HelpPopUpV2 = () => import("Components/Appic/Help/HelpPopUpV2");

export default {
    name: "GradesListing",
    components: { AddGrade, ExportTable, HelpPopUpV2 },
    props: ['addDialog'],
    data() {
        return {
            colsToDelete: {
                start_col: 0,
                ncols: 1
            },
            dialogs: {
                error: false,
                error_message: ""
            },
            editDialog: null,
            filterOptions: {
                office: 1
            },
            hideDefaultFooter: true,
            loading: {
                fetch: false,
                filterResults: false,
                grades: false
            },
            searchField: null,
            searchTerm: null,
            selectedGradeId: null,
            selectedGradeName: null,
            tableHeight: '500',
            tableOptions: {
                page: 1

            },
            totalGrades: 0,
            updateMode: false
        }
    },
    computed: {
        ...mapGetters('grade', {
            allGrades: 'allGrades'
        }),
        ...mapGetters('productgroup',{
            allProductGroups: 'allProductGroups'
        }),
        headers () {
            return [
                // {
                //     id: 0,
                //     text: this.$t('message.no'),
                //     value: 'index',
                //     class: 'light-green lighten-3 pa-1 width-1-pct',
                //     sortable: false
                // },
                {
                    id: 1,
                    text: this.$t('message.actions'),
                    value: 'Grade.id',
                    class: 'light-green lighten-3 pa-1 width-1-pct',
                    sortable: false
                },
                {
                    id: 2,
                    text: this.$t('message.grade') + ' (' + this.$t('message.ncr') + ')',
                    value: 'Grade.title',
                    class: 'light-green lighten-3 pa-1',
                    sortable: true
                },
                {
                    id: 3,
                    text: this.$t('message.gradeGroup') + ' (' + this.$t('message.appic') + ')',
                    value: 'Parent.title',
                    class: 'light-green lighten-3 pa-1',
                    sortable: true
                },
                {
                    id: 4,
                    text: this.$t('message.productGroups') + ' (' + this.$t('message.zoho') + ')',
                    value: 'Grade.productgroups',
                    class: 'light-green lighten-3 pa-1',
                    sortable: false
                }
            ]
        },
        pageKey() {
            return Math.floor(Math.random() * 100)
        }
    },
    methods: {
        ...mapActions('grade',{
            deleteGradeById: 'deleteGradeById',
            getAllGrades: 'getAllGrades',
            resetState: 'resetState'
        }),
        ...mapActions('productgroup',{
            getAllProductGroups: 'getAllProductGroups'
        }),
        async deleteGrade(val, title) {
            if(await this.$root.$confirm(this.$t('message.deleteGrade') + ' ' +  title, this.$t('message.confirmations.continueGradeCancelAction'), {color: 'orange'})){
                this.deleteGradeById(val).then((status) => {
                    if(status == 'done'){
                        this.$toast.success(this.$t('message.successes.gradeDeleted'),
                            {
                                classes: ['icon-float-left'],
                                icon: 'check_circle_outline'
                            }
                        )
                        this.resetState()
                        this.getAllGrades()
                    } else {
                        this.$toast.error(this.$t('message.errors.gradeNotDeleted'),
                            {
                                classes: ['icon-float-left'],
                                icon: 'error_outline'
                            }
                        )
                    }
                })
            }
        },
        editDialogClosed () {
            this.editDialog = false;
            if(this.updateMode == false){
                this.$emit('add-dialog-closed')
            }
        },
        filterResults () {
            this.loading.filterResults = true
            let _this = this
            setTimeout(function(){
                _this.loading.filterResults = false
            },700)
        },
        getProuductGroupText(productId) {
            let product = this.allProductGroups.find(p => {
                return p.ProductGroup.id == productId
            })
            if(product) {
                return product.ProductGroup.title
            } else {
                return ''
            }
        },
        gradeCreated () {
            this.editDialog = false;
            this.resetState()
            this.$emit('reload')
        },
        gradeUpdated () {
            this.editDialog = false;
            this.resetState()
            this.$emit('reload')
        },
        handleResize() {
            this.tableHeight = window.innerHeight - (285);
        },
        openConvertDialog () {
            this.convertDialog = true
        },
        openCreateGradeDialog () {
            this.updateMode = false
            this.editDialog = true
        },
        openEditGradeDialog (gradeId, gradeName) {
            this.selectedGradeId = gradeId
            this.selectedGradeName = gradeName
            this.updateMode = true
            this.editDialog = true
        }
    },
    watch: {
        addDialog(value) {
            if(value) {
                this.openCreateGradeDialog ()
            }
        }
    },
    created() {
        this.resetState()
        if(this.allGrades.length == 0) this.getAllGrades()
        if(this.allProductGroups.length == 0) this.getAllProductGroups()
        window.addEventListener('resize', this.handleResize)
    },
    mounted() {
        this.handleResize()
    },
    destroyed() {
        window.removeEventListener('resize', this.handleResize)
    }
}
</script>

<style>
.v-data-table-header th {
    white-space: nowrap;
}
</style>